
.appIconContainer {
    /*background-image: radial-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%), radial-gradient(rgba(0, 0, 0, 0) 33%, rgba(0, 0, 0, 0.3) 166%);*/
    background-color: #ffffff;
    width: 90px;
    height: 90px;
    color: #4d4d4d;
    border-radius: 10px;
    align-content: center;
    text-align: center;
    vertical-align: middle;
    margin: auto;

    box-shadow: 0px 0px 0px 1px #3e9ef8;
    transition: box-shadow 0.3s linear;
    /*margin: 0.5em; !* Increased margin since the box-shado expands outside the element, like outline *!*/
}

.appIconContainer:hover { box-shadow: 0px 0px 0px 6px #3e9ef8; cursor: pointer; }

.appIconContainer > div {
    padding: 12px 5px 10px 5px;
}

.appIconContainer > div > p {
    font-size: 11px;
    width: 82px;
    margin: 5px 0;
    padding: 1%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.cardBgOpacity {
    background-color: #e5e5e5;
    padding:10px;
    border-radius: 8px;
    margin-top: 15px;
}