.container {
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;

  background: rgba(238, 242, 255, 0) url("https://i.imgur.com/IEVZtax.png")
    no-repeat left top;
  background-size: contain;
  background-attachment: fixed;
}

.appsContainerOuter {
  width: 100%;
  display: flex;
  justify-content: center;
}

.appsContainer {
  max-width: 960px;
}

.cardBgOpacity {
  background-color: rgba(242, 244, 251, 0.2);
  padding: 10px;
  border-radius: 8px;
  margin-top: 15px;
  border: 1px solid rgb(227, 223, 247);

  box-shadow: -4px 2px 11px 0px rgba(224, 224, 224, 0.72);
  -webkit-box-shadow: -4px 2px 11px 0px rgba(224, 224, 224, 0.72);
  -moz-box-shadow: -4px 2px 11px 0px rgba(224, 224, 224, 0.72);
}

.overlay {
  background-attachment: fixed;
  background-image: radial-gradient(
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    radial-gradient(rgba(0, 0, 0, 0) 33%, rgba(0, 0, 0, 0.3) 166%);
  background-position-y: 0%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  opacity: 1;
  position: absolute;
  top: 0px;
  transition: opacity 0.3s linear 0s;
  width: 100%;
}
